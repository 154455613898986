import React, { useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import { Heading1, IntenseText, ExpressiveText } from 'components/design'
import Preloader from 'components/Preloader'
import BriefLayout from './layout'
import FormStep from './step'
import Model from './model'
import ShortStep from './short-step'

export default function BriefContoller({ briefId }) {
	const briefModel = useRef(null)
	const [currentStep, setCurrentStep] = useState(null)
	const [isStepLoading, setIsStepLoading] = useState(false)

	useEffect(() => {
		// console.log('Rendering')
		if (briefModel.current || currentStep === 'Thank you') {
			return
		}

		if (briefId === 'new') {
			// Model.initialiseNew().then((data) => {
			// 	briefModel.current = data
			// 	// console.log(briefModel.current)
			// 	setCurrentStep('Short form')
			// })
			briefModel.current = new Model()
			setCurrentStep('Short form')
		} else {
			navigate('/404/')
			//	Model.initialiseExisting(briefId).then((data) => {
			//		if (typeof data === 'string') {
			//			navigate('/404/')
			//			return
			//		}

			//		briefModel.current = data
			//		console.log(briefModel.current)
			//		setCurrentStep(data.steps[0])
			//	})
		}
	}, [currentStep, briefId])

	const submitNewBrief = (incomingFields, nextStep, uploads) => {
		setIsStepLoading(true)
		briefModel.current.submitNew(incomingFields, uploads).then((briefId) => {
			if (nextStep === 'Thank you') {
				navigate('/brief-sent/')
				return
			}

			navigate(`/brief/${briefId}/`, { replace: true })
			console.log(briefModel.current.steps)
			setCurrentStep(nextStep)
			setIsStepLoading(false)
			window.scrollTo({ top: document.querySelector('h1')?.offsetTop || 0 })
		})
	}

	const saveStep = (incomingFields, nextDirection, uploads) => {
		console.log(incomingFields, uploads)
		setIsStepLoading(true)
		briefModel.current.save(incomingFields, uploads).then((res) => {
			const currentIndex = briefModel.current.steps.indexOf(currentStep)

			if (currentIndex + nextDirection <= briefModel.current.steps.length - 1) {
				setCurrentStep(briefModel.current.steps[currentIndex + nextDirection])
				setIsStepLoading(false)
				window.scrollTo({ top: document.querySelector('h1')?.offsetTop || 0 })
			} else {
				navigate('/brief-sent/')
			}
		}, console.error)
	}

	const preloader = (
		<div className="flex justify-center">
			<Preloader side={220} />
		</div>
	)
	let output = preloader

	if (currentStep === 'Short form') {
		output = (
			<>
				<Heading1>Project brief</Heading1>
				<ExpressiveText className="mt-12 lg:w-11/12">
					In order to prepare a quote for your project we need information about
					it.
					<br />
					Please fill in the brief with your request details.
					<br />
				</ExpressiveText>
				<ExpressiveText className="mt-8 lg:w-11/12 ">
					Required fields are marked with an&nbsp;asterisk&nbsp;*
				</ExpressiveText>
				{isStepLoading ? (
					preloader
				) : (
					 <ShortStep
						 fields={briefModel.current.schema['Short form']}
						 saveFields={submitNewBrief}
						 // bottomMessage={
						 // 	<>
						 // 		<p className="uppercase mb-4 font-semibold tracking-px">
						 // 			Please note
						 // 		</p>
						 // 		<RegularText className="text-sm">
						 // 			<p>
						 // 				You can either submit the short brief or add more details by
						 // 				proceeding to the next step. The information provided in
						 // 				this short brief is enough for us to get back to you.
						 // 			</p>
						 // 			<p>
						 // 				However we strongly advise to provide more details so the
						 // 				project can be completed in a more efficient and smooth way.
						 // 			</p>
						 // 		</RegularText>
						 // 	</>
						 // }
					 />
				 )}
			</>
		)
	} else if (currentStep) {
		output = (
			<>
				<Heading1>Detailed project brief</Heading1>
				{currentStep === 'General information' ? (
					<CustomerInfo model={briefModel.current} />
				) : null}
				<StepsNavi steps={briefModel.current.steps} currentStep={currentStep} />
				<IntenseText className="mt-4 lg:w-11/12">
					Required fields are marked with an&nbsp;asterisk&nbsp;*
				</IntenseText>
				{isStepLoading ? (
					preloader
				) : (
					 <FormStep
						 fields={briefModel.current.schema[currentStep]}
						 existingValues={briefModel.current.getValuesForStep(currentStep)}
						 saveFields={saveStep}
						 previousAllowed={
							 briefModel.current.steps.indexOf(currentStep) > 0
						 }
						 uploadFolder={briefModel.current.getValueByName('Your name')}
						 existingUploads={briefModel.current.existingUploads}
					 />
				 )}
			</>
		)
	}

	return <BriefLayout>{output}</BriefLayout>
}

const StepsNavi = ({ steps, currentStep }) => (
	<div className="mt-8 flex items-center border-b border-black border-opacity-25 py-4 text-sm lg:w-11/12 lg:text-base">
		{steps.map((step, index) => (
			<React.Fragment key={step}>
				<div
					className={`text-xs font-semibold uppercase tracking-px md:text-sm ${
						step === currentStep ? 'text-accent' : ''
					}`}
				>
					{step}
				</div>
				{index < steps.length - 1 ? (
					<div className="mx-4 opacity-50 lg:mx-12">|</div>
				) : null}
			</React.Fragment>
		))}
	</div>
)

const CustomerInfo = ({ model }) => {
	const customerLine = [
		model.getTextValue('Your name'),
		model.getTextValue('Company'),
		model.getTextValue('Your email'),
	]
		.filter(Boolean)
		.join(', ')
	const imageCount = model.getTextValue('Number of images')
	const animationLength = model.getTextValue('Animation length')
	const deadline = model.getTextValue('Deadline')

	return (
		<div className="mt-8 bg-black bg-opacity-5 py-4 text-pale lg:w-11/12">
			<p>
				<strong>Customer: </strong>
				{customerLine}
			</p>
			<p className="mt-3">
				<strong>CGI project type: </strong>
				{model.getTextValue('CGI project type')}
			</p>

			<p className="mt-3">
				{imageCount ? (
					<>
						<strong>Number of images: </strong>
						{imageCount}
					</>
				) : null}
				{animationLength ? (
					<>
						<strong className={imageCount ? 'ml-6' : ''}>
							Animation length:{' '}
						</strong>
						{animationLength}
					</>
				) : null}
				{deadline ? (
					<>
						<strong className={imageCount || animationLength ? 'ml-6' : ''}>
							Deadline:{' '}
						</strong>
						{deadline}
					</>
				) : null}
			</p>
		</div>
	)
}