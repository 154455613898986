import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
	BriefCheckboxList,
	BriefDateInput,
	BriefEmailInput,
	BriefNumberInput,
	BriefTextArea,
	BriefTextInput,
} from './controls'
import UppyUploader from './uppy-uploader'

export default function FormStep({
	fields,
	saveFields,
	existingValues,
	previousAllowed,
	uploadFolder,
	existingUploads,
}) {
	const [isUploadInProgress, setIsUploadInProgress] = useState(false)
	const uploadsRegistry = useRef({})
	const registerUploads = (fieldName, uploads) => {
		uploadsRegistry.current[fieldName] = uploads

		console.log('REGISTERED', uploadsRegistry.current)
	}

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: existingValues,
	})

	const typesToComponents = {
		text: BriefTextInput,
		textarea: BriefTextArea,
		date: BriefDateInput,
		number: BriefNumberInput,
		email: BriefEmailInput,
		'multiple-checkbox': BriefCheckboxList,
	}

	const buildUploader = (name) => (
		<>
			<UppyUploader
				folderName={uploadFolder}
				fieldName={name}
				notifyUploadStatus={setIsUploadInProgress}
				notifyUploadList={registerUploads}
			/>
			{existingUploads[name] ? (
				<div className="mt-3 bg-black bg-opacity-5 py-2 text-xs text-pale">
					<strong>Previously uploaded: </strong>
					{existingUploads[name].map(({ name, driveLink }) => (
						<a
							className="accent-hover mr-4"
							href={driveLink}
							key={driveLink}
							target="_blank"
							rel="noreferrer"
						>
							{name}
						</a>
					))}
				</div>
			) : null}
		</>
	)

	return (
		<form className="flex w-full flex-wrap justify-between">
			{fields.map(
				({
					fieldId,
					type,
					fieldName,
					placeholder,
					required,
					list_options,
					attach_uploader,
				}) => {
					const inputProps = {
						fieldName,
						placeholder,
						required,
						list_options,
						...register(
							fieldId,
							required ? { required: 'This field is required' } : {}
						),
						error: errors[fieldId],
					}
					if (type === 'textarea') {
						inputProps.currentText = watch(fieldId) || ''
					}

					if (attach_uploader) {
						if (type === 'textarea') {
							inputProps.attachedUploaders = [
								buildUploader(fieldName + ' uploads'),
							]
						} else if (type === 'multiple-checkbox') {
							inputProps.attachedUploaders = list_options.map(
								({ option }) =>
									buildUploader(fieldName + ': ' + option + ' uploads')
							)

							inputProps.fieldValue = watch(fieldId)
						}
					}

					return (
						<div
							className={`mt-8 md:mt-12 ${
								type === 'multiple-checkbox' ? 'w-1/2' : 'w-11/12'
							}`}
							key={fieldName}
						>
							{typesToComponents[type](inputProps)}
						</div>
					)
				}
			)}

			<div className="mt-12 w-11/12">
				<button
					onClick={handleSubmit((data) => {
						console.log('Saving', data)
						saveFields(data, 1, uploadsRegistry.current)
					})}
					disabled={isUploadInProgress}
					title={isUploadInProgress ? 'Please wait for upload to finish' : ''}
					className="h-16 w-2/3 bg-black font-semibold uppercase tracking-px text-white disabled:opacity-50 md:w-2/5"
				>
					Save and proceed
				</button>

				{previousAllowed ? (
					<button
						onClick={handleSubmit((data) => {
							console.log('Saving', data)
							saveFields(data, -1, uploadsRegistry.current)
						})}
						disabled={isUploadInProgress}
						title={
							isUploadInProgress ? 'Please wait for upload to finish' : ''
						}
						className="mt-4 h-16 w-2/3 font-semibold uppercase tracking-px disabled:opacity-50 md:mt-0 md:w-3/5"
					>
						Back to previous step
					</button>
				) : null}
			</div>
		</form>
	)
}
