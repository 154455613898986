import axios from 'axios'

// const endpoint = 'http://localhost:2008/new-brief'
const endpoint =
	'https://europe-west3-cuub-website.cloudfunctions.net/zCrmConnector/new-brief'

class Model {
	constructor(existing = {}, briefId) {
		this.briefId = briefId
		this.schema = { 'Short form': SHORT_FORM }
		this.fields = SHORT_FORM.reduce((carry, field) => {
			return Object.assign(carry, { [field.fieldName]: field })
		}, {})

		// const loadedSteps = Object.keys(schema)
		// this.fields = {}
		// loadedSteps.forEach((step) => {
		// 	schema[step].forEach((field) => {
		// 		this.fields[field.fieldName] = field
		// 	})
		// })

		// this.steps = [...loadedSteps]
		// this.steps.shift()

		const { values, uploads } = existing
		if (values) {
			this.values = values
			this.determineStepsBySelectedTypes()
		} else {
			this.values = {}
			Object.keys(this.fields).forEach((fieldName) => {
				this.values[this.fields[fieldName].fieldId] = ''
			})
		}
		this.existingUploads = uploads || {}
	}

	// TODO: check status code
	static initialiseNew() {
		return axios.post(endpoint, { task: 'get-form-data' }).then((res) => {
			return new Model(res.data)
		}, console.error)
	}

	static async initialiseExisting(briefId) {
		const schemaRes = await axios.post(endpoint, { task: 'get-form-data' })
		try {
			const existingRes = await axios.post(endpoint, {
				task: 'get-existing-brief',
				briefId,
			})

			return new Model(schemaRes.data, existingRes.data, briefId)
		} catch (err) {
			return 'NOT FOUND'
		}
	}

	submitNew(incomingValues, uploadsList) {
		this.values = { ...this.values, ...incomingValues }
		// this.determineStepsBySelectedTypes()
		const queryString = window.location.search;
		const firstTimeWorking = this.getValueByName('Is this your first time working with CUUB?');
		let normalizedUtmParams = null;

		if(firstTimeWorking === "Yes") {
			const utmParams = this.getValueByName('How did you hear about CUUB?*');
			switch (utmParams) {
				case "Personal recommendation": {
					normalizedUtmParams = "Personal recommendation"
					break;
				}
				case "Events": {
					normalizedUtmParams = "Events"
					break;
				}
				case "Social Media": {
					normalizedUtmParams = "Social Media"
					break;
				}
				case "Search Google/Bing": {
					normalizedUtmParams = "Search Google/Bing"
					break;
				}
				case "Office Pitch": {
					normalizedUtmParams = "Office Pitch"
					break;
				}
				case "Ads": {
					normalizedUtmParams = "Ads"
					break;
				}
				case "Other": {
					normalizedUtmParams = "Other"
					break;
				}
			}
		}

		if(queryString?.length) {
			console.log(queryString);
			const urlParams = new URLSearchParams(queryString);
			const utmParams = urlParams.get('utm_medium');
			switch (utmParams) {
				case "smm": {
					normalizedUtmParams = "Social media"
					break;
				}
				case "email": {
					normalizedUtmParams = "Email marketing"
					break;
				}
				case "ads": {
					normalizedUtmParams = "Ads"
					break;
				}
			}
		}

		return axios
			.post(endpoint, {
				// task: 'submit-new-brief',
				brief: this.values,
				initialUploads: {
					name: this.getValueByName('Your name'),
					list: uploadsList,
				},
				utmParams: normalizedUtmParams || 'Other',
			})
			.then((res) => {
				// TODO: check status
				console.log('RESPONSE', res.status, res.data)
				// const { briefTaskId, uploads } = res.data

				// this.briefId = briefTaskId
				// // this.values = values
				// this.existingUploads = uploads

				// return this.briefId
				return res.status === 200
			}, console.error)
	}

	save(incomingValues, newUploads) {
		// this.values = { ...this.values, ...incomingValues }

		return axios
			.post(endpoint, {
				task: 'save-brief-fields',
				briefId: this.briefId,
				fields: incomingValues,
				newUploads,
				existingUploads: this.existingUploads,
			})
			.then((res) => {
				const { values, uploads } = res.data

				this.existingUploads = uploads
				this.values = values

				return 'OK'
			}, console.error)
	}

	getValuesForStep(step) {
		const fieldIds = this.schema[step].map(({ fieldId }) => fieldId)

		return fieldIds.reduce((carry, fieldId) => {
			carry[fieldId] = this.values[fieldId]
			return carry
		}, {})
	}

	getValueByName(fieldName) {
		const id = this.fields[fieldName].fieldId
		return this.values[id]
	}

	getTextValue(fieldName) {
		const field = this.fields[fieldName]
		const id = field.fieldId

		if (field.type === 'multiple-checkbox') {
			return field.list_options
				.filter(({ key }) => this.values[id].indexOf(key) > -1)
				.map(({ option }) => option)
				.join(', ')
		}

		return this.values[id]
	}

	determineStepsBySelectedTypes() {
		const typeFieldName = 'CGI project type'
		const typeField = this.fields[typeFieldName]
		const typeValues = this.getValueByName(typeFieldName)

		const selectedTypes = typeField.list_options
			.filter(({ key }) => typeValues.indexOf(key) > -1)
			.map(({ option }) => option)
			.join('')

		if (selectedTypes.indexOf('animat') < 0) {
			// remove the Animation step
			this.steps = this.steps.filter(
				(step) => step.toLowerCase().indexOf('animat') < 0
			)
		}
		if (selectedTypes.indexOf('render') < 0) {
			// remove the Render step
			this.steps = this.steps.filter(
				(step) => step.toLowerCase().indexOf('render') < 0
			)
		}
	}
}

export default Model

const SHORT_FORM = [
	{
		fieldName: 'Your name',
		type: 'text',
		required: true,
		placeholder: 'Write your name',
		description: '',
		attach_uploader: false,
		fieldId: '76060',
	},
	{
		fieldName: 'Your email',
		type: 'email',
		required: true,
		placeholder: 'Write your email',
		description: '',
		attach_uploader: false,
		fieldId: '76062',
	},
	{
		fieldName: 'Company',
		type: 'text',
		required: true,
		placeholder: 'Write the name of your company',
		description: '',
		attach_uploader: false,
		fieldId: '76064',
	},
	{
		fieldName: 'Is this your first time working with CUUB?',
		type: 'single-checkbox',
		required: true,
		placeholder: '',
		description: '',
		attach_uploader: false,
		fieldId: '76108',
		list_options: [
			{
				key: 'Yes',
				option: 'Yes' },
			{
				key: 'No, we already had joint projects',
				option: 'No, we already had joint projects'
			},
		],
	},
	{
		fieldName: 'Type of services',
		type: 'multiple-checkbox',
		required: true,
		placeholder: '',
		description: '',
		attach_uploader: false,
		fieldId: '76072',
		list_options: [
			// { key: '1', option: 'Exterior rendering' },
			// { key: '2', option: 'Interior rendering' },
			// { key: '10', option: 'Exterior animation' },
			// { key: '11', option: 'Interior animation' },
			{ key: '20', option: 'Concept Creation' },
			{ key: '21', option: 'Still Images' },
			{ key: '22', option: '3D Animation' },
			{ key: '23', option: 'VR Experience' },
			{ key: '24', option: 'Product Rendering' },
			{ key: '25', option: 'Brand Identity' },
			{ key: '26', option: 'Web Development' },
			{ key: '27', option: 'Research & Analysis' },
			{ key: '28', option: 'Strategy & Consulting' },
		],
	},
	{
		fieldName: 'How did you hear about CUUB?*',
		type: 'single-checkbox',
		required: false,
		placeholder: '',
		description: '',
		attach_uploader: false,
		fieldId: '76114',
		list_options: [
			{ key: 'Personal recommendation', option: 'Personal recommendation' },
			{ key: 'Events', option: 'Events' },
			{ key: 'Social Media', option: 'Social Media' },
			{ key: 'Search Google/Bing', option: 'Search Google/Bing' },
			{ key: 'Ads', option: 'Ads' },
			{ key: 'Office Pitch', option: 'Office Pitch' },
			{ key: 'Other', option: 'Other' },
		],
	},
	{
		fieldName: 'Message',
		type: 'textarea',
		required: true,
		placeholder: 'Describe your project and its goals here',
		description: '',
		attach_uploader: false,
		fieldId: '76068',
	},
	{
		fieldName: 'Project',
		type: 'textarea',
		required: true,
		placeholder: 'Write the type of building/product',
		description: '',
		attach_uploader: false,
		fieldId: '76052',
	},
	// {
	// 	fieldName: 'Deadline',
	// 	type: 'date',
	// 	required: false,
	// 	placeholder: '',
	// 	description: '',
	// 	attach_uploader: false,
	// 	fieldId: '76044',
	// },
	// {
	// 	fieldName: 'Number of images',
	// 	type: 'number',
	// 	required: false,
	// 	placeholder: '',
	// 	description: '',
	// 	attach_uploader: false,
	// 	fieldId: '76046',
	// },
	// {
	// 	fieldName: 'Animation length',
	// 	type: 'text',
	// 	required: false,
	// 	placeholder: '',
	// 	description: '',
	// 	attach_uploader: false,
	// 	fieldId: '76074',
	// },
	// {
	// 	fieldName: 'References',
	// 	type: 'textarea',
	// 	required: false,
	// 	placeholder: 'Paste links here one at a line',
	// 	description: '',
	// 	attach_uploader: true,
	// 	fieldId: '76066',
	// },
	// {
	// 	fieldName: 'Building type',
	// 	type: 'multiple-checkbox',
	// 	required: false,
	// 	placeholder: '',
	// 	description: '',
	// 	attach_uploader: false,
	// 	fieldId: '76070',
	// 	list_options: [
	// 		{ key: '5', option: 'Residential' },
	// 		{ key: '6', option: 'Commercial' },
	// 		{ key: '7', option: 'Skyscraper' },
	// 		{ key: '8', option: 'Public place' },
	// 	],
	// },
	// {
	// 	fieldName: 'Input files',
	// 	type: 'multiple-checkbox',
	// 	required: false,
	// 	placeholder: '',
	// 	description: '',
	// 	attach_uploader: true,
	// 	fieldId: '76124',
	// 	list_options: [
	// 		{ key: '54', option: 'Site photos' },
	// 		{ key: '57', option: 'Site plan' },
	// 		{ key: '55', option: 'Landscape plan' },
	// 		{ key: '51', option: 'Floor plans' },
	// 		{ key: '52', option: 'Roof plan' },
	// 		{ key: '53', option: 'Building elevations' },
	// 		{ key: '56', option: '3DsMax / Revit / SketchUp model' },
	// 		{ key: '58', option: 'Camera angle markup' },
	// 	],
	// },
	// {
	// 	fieldName: 'Input files links',
	// 	type: 'textarea',
	// 	required: false,
	// 	placeholder:
	// 		'Alternatively, if you have input files accessible via public links, paste them one per line',
	// 	description: '',
	// 	attach_uploader: false,
	// 	fieldId: '76076',
	// },
	// {
	// 	fieldName: 'Google Maps coordinates',
	// 	type: 'text',
	// 	required: false,
	// 	placeholder: 'Paste a Google Maps link to the building location',
	// 	description: '',
	// 	attach_uploader: false,
	// 	fieldId: '76120',
	// },
]