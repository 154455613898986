import React from 'react'
import { Helmet } from 'react-helmet'
import BriefController from 'templates/brief/controller'

const BriefPage = ({ params: { briefId } }) => {
	return (
		<>
			<BriefController briefId={briefId} />
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>
		</>
	)
}

export default BriefPage
