import React from 'react'
import GlobalIncludes from 'templates/layout/global-includes'
import Logo from './logo'

const BriefLayout = ({ children }) => (
	<>
		<div className="mt-6 h-12 px-2 lg:px-10">
			<Logo />
		</div>
		<section
			className={`mx-auto mt-16 min-h-screen max-w-3xl px-4 pb-16 text-sm md:px-0 md:text-base`}
		>
			{children}
		</section>
		<footer className="py-4 text-center">
			<p className="text-sm opacity-50">
				&copy; {new Date().getFullYear()} CUUB Studio. All rights reserved.
			</p>
		</footer>
		<GlobalIncludes />
	</>
)

export default BriefLayout
