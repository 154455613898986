import React, { useEffect, useState } from 'react'

const BriefTextInput = ({ fieldName, placeholder, required, error, ...listeners }) => (
	<label className="">
		<p className="mb-4 font-semibold uppercase tracking-px">
			{fieldName}
			{required ? '*' : ''}
		</p>
		<input
			className={`w-full border-0 border-b bg-transparent px-0 pb-2 text-sm md:text-base ${
				error ? 'border-red-500' : ''
			} focus:outline-none focus:ring-black focus:ring-opacity-0`}
			type="text"
			placeholder={placeholder}
			{...listeners}
		/>
		{error && <span className="mt-1 text-sm text-red-500">{error.message}</span>}
	</label>
)

const BriefTextArea = ({
	                       fieldName,
	                       placeholder,
	                       required,
	                       error,
	                       currentText,
	                       attachedUploaders,
	                       ...listeners
                       }) => {
	const lineLimit = 69
	const [rowCount, setRowCount] = useState(1)

	useEffect(() => {
		const lineBreaks = currentText.replace(/[^\n]/g, '').length

		setRowCount(Math.ceil(currentText.length / lineLimit) + lineBreaks || 1)
	}, [currentText])

	return (
		<>
			<label className="">
				<p className="mb-4 font-semibold uppercase tracking-px">
					{fieldName}
					{required ? '*' : ''}
				</p>
				<textarea
					className={`w-full border-0 border-b bg-transparent px-0 pb-2 text-sm md:text-base ${
						error ? 'border-red-500' : ''
					} focus:outline-none focus:ring-black focus:ring-opacity-0`}
					rows={rowCount}
					placeholder={placeholder}
					{...listeners}
				/>
				{error && <p className="mt-1 text-sm text-red-500">{error.message}</p>}
			</label>

			{attachedUploaders?.length ? (
				<>
					<p className="my-3 text-pale">or</p>
					{attachedUploaders[0]}
				</>
			) : null}
		</>
	)
}

const BriefEmailInput = ({ fieldName, placeholder, required, error, ...listeners }) => (
	<label className="">
		<p className="mb-4 font-semibold uppercase tracking-px">
			{fieldName}
			{required ? '*' : ''}
		</p>
		<input
			className={`w-full border-0 border-b bg-transparent px-0 pb-2 text-sm md:text-base ${
				error ? 'border-red-500' : ''
			} focus:outline-none focus:ring-black focus:ring-opacity-0`}
			type="email"
			placeholder={placeholder}
			{...listeners}
		/>
		{error && <span className="mt-1 text-sm text-red-500">{error.message}</span>}
	</label>
)

const BriefDateInput = ({
	                        fieldName,
	                        placeholder,
	                        min,
	                        max,
	                        required,
	                        error,
	                        ...listeners
                        }) => (
	<label className="">
		<p className="mb-4 font-semibold uppercase tracking-px">
			{fieldName}
			{required ? '*' : ''}
		</p>
		<input
			className="w-full border-0 border-b bg-transparent px-0 pb-2 text-sm focus:outline-none focus:ring-black focus:ring-opacity-0 md:text-base"
			type="date"
			min={min}
			max={max}
			placeholder={placeholder}
			{...listeners}
		/>
		{error && <span className="mt-1 text-sm text-red-500">{error.message}</span>}
	</label>
)

const BriefNumberInput = ({
	                          fieldName,
	                          placeholder,
	                          min,
	                          max,
	                          required,
	                          error,
	                          ...listeners
                          }) => (
	<label className="">
		<p className="mb-4 font-semibold uppercase tracking-px">
			{fieldName}
			{required ? '*' : ''}
		</p>
		<input
			className={`w-full border-0 border-b bg-transparent px-0 pb-2 text-sm md:text-base ${
				error ? 'border-red-500' : ''
			} focus:outline-none focus:ring-black focus:ring-opacity-0`}
			type="number"
			min={min}
			max={max}
			placeholder={placeholder}
			{...listeners}
		/>
		{error && <span className="mt-1 text-sm text-red-500">{error.message}</span>}
	</label>
)

const BriefCheckboxList = ({
	                           fieldName,
	                           placeholder,
	                           required,
	                           error,
	                           list_options,
	                           attachedUploaders,
	                           fieldValue, // hack to determine visible uploaders
	                           ...listeners
                           }) => (
	<div className="">
		<p
			className={`mb-4 font-semibold uppercase tracking-px`}
		>
			{fieldName}
			{required ? '*' : ''}
		</p>
		{list_options.map(({ key, option }, index) => (
			<React.Fragment key={key}>
				<label className="mb-4 flex select-none items-center">
					<input
						className="brief-checkbox focus:outline-none focus:ring-black focus:ring-opacity-0"
						type="checkbox"
						value={key}
						{...listeners}
					/>
					<span className="ml-2">{option}</span>
				</label>

				{attachedUploaders && fieldValue && fieldValue.indexOf(key) > -1 ? (
					<div className="mb-4">{attachedUploaders[index]}</div>
				) : null}
			</React.Fragment>
		))}
		{error && <span className="text-sm text-red-500">{error.message}</span>}
	</div>
)

const BriefRadioGroup = ({
	                         fieldName,
	                         placeholder,
	                         required,
	                         error,
	                         list_options,
	                         attachedUploaders,
	                         fieldValue, // hack to determine visible uploaders
	                         ...listeners
                         }) => (
	<div className="">
		<p
			className={`mb-4 font-semibold uppercase tracking-px`}
		>
			{fieldName}
			{required ? '*' : ''}
		</p>
		{list_options.map(({ key, option }, index) => (
			<React.Fragment key={key}>
				<label className="mb-4 flex select-none items-center">
					<input
						className="brief-checkbox focus:outline-none focus:ring-black focus:ring-opacity-0"
						type="radio"
						value={key}
						{...listeners}
					/>
					<span className="ml-2">{option}</span>
				</label>

				{attachedUploaders && fieldValue && fieldValue.indexOf(key) > -1 ? (
					<div className="mb-4">{attachedUploaders[index]}</div>
				) : null}
			</React.Fragment>
		))}
		{error && <span className="text-sm text-red-500">{error.message}</span>}
	</div>
)

export {
	BriefCheckboxList,
	BriefDateInput,
	BriefEmailInput,
	BriefNumberInput,
	BriefTextInput,
	BriefTextArea,
	BriefRadioGroup,
}