import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
	BriefCheckboxList,
	BriefRadioGroup,
	BriefDateInput,
	BriefEmailInput,
	BriefNumberInput,
	BriefTextArea,
	BriefTextInput,
} from './controls'
import UppyUploader from './uppy-uploader'

const lookupFieldByName = (fields, lookupName) =>
	fields.filter(({ fieldName }) => fieldName === lookupName)[0]

const doArraysIntersect = (arr1, arr2) => arr1.filter((x) => arr2.includes(x)).length > 0
export default function ShortStep({ fields, saveFields, bottomMessage }) {
	const uploadFolderName = useRef(new Date().toISOString())
	const [isUploadInProgress, setIsUploadInProgress] = useState(false)
	const uploadsRegistry = useRef({})
	const registerUploads = (fieldName, uploads) => {
		uploadsRegistry.current[fieldName] = uploads

		console.log('REGISTERED', uploadsRegistry.current)
	}
	const buildUploader = (name) => (
		<UppyUploader
			folderName={uploadFolderName.current}
			fieldName={name}
			notifyUploadStatus={setIsUploadInProgress}
			notifyUploadList={registerUploads}
		/>
	)

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {},
	})

	const messageFieldId = lookupFieldByName(fields, 'Message').fieldId
	const projectTypeField = lookupFieldByName(fields, 'Type of services')
	const watchPersonalAgreement = watch('personal_agreement')
	const watchHowDoYouHear = watch('76108')
	const renderKeys = projectTypeField.list_options
		.filter(
			({ option }) =>
				option.toLowerCase().includes('render') ||
				option.toLowerCase().includes('image')
		)
		.map(({ key }) => key)
	const animationKeys = projectTypeField.list_options
		.filter(({ option }) => option.toLowerCase().includes('animation'))
		.map(({ key }) => key)
	const isImagesNumberDisplayed = doArraysIntersect(
		renderKeys,
		watch(projectTypeField.fieldId) || []
	)
	const isAnimationLengthDisplayed = doArraysIntersect(
		animationKeys,
		watch(projectTypeField.fieldId) || []
	)

	const submitShortForm = (data) => {
		delete data.special_offers;
		delete data.personal_agreement;
		console.log('Saving', data);
		saveFields(data, 'Thank you', uploadsRegistry.current)
	}

	// const saveAndProceed = (data) => {
	// 	console.log('Saving', data)
	// 	saveFields(data, 'General information', uploadsRegistry.current)
	// }

	const typesToComponents = {
		text: BriefTextInput,
		textarea: BriefTextArea,
		date: BriefDateInput,
		number: BriefNumberInput,
		email: BriefEmailInput,
		'multiple-checkbox': BriefCheckboxList,
		'single-checkbox': BriefRadioGroup,
	}

	const rules = {
		'Your name': {
			required: 'This field is required',
			minLength: {
				value: 4,
				message: 'Name cannot be shorter than 4 characters',
			},
		},
		'Your email': {
			required: 'This field is required',
			pattern: {
				value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				message: 'Enter a valid email address',
			},
		},
		'Message': {
			required: 'This field is required',
			maxLength: {
				value: 2000,
				message: 'Please describe your project more compactly',
			},
		},
		'Project': {
			required: 'This field is required',
			maxLength: {
				value: 2000,
				message: 'Please describe your product more compactly',
			},
		},
		'How did you hear about CUUB?*': {
			required: watchHowDoYouHear === 'Yes' ? 'This field is required' : false,
		},
	}
	const inputs = {}

	fields.forEach(
		({
			 fieldId,
			 type,
			 fieldName,
			 placeholder,
			 required,
			 list_options,
			 attach_uploader,
		 }) => {
			const inputProps = {
				fieldName,
				placeholder,
				required,
				list_options,
				...register(
					fieldId,
					rules[fieldName] ||
					(required ? { required: 'This field is required' } : {})
				),
				error: errors[fieldId],
			}
			if ((type === 'date' || type === 'number') && rules[fieldName]) {
				if (rules[fieldName]?.min?.value) {
					inputProps.min = rules[fieldName].min.value
				}
				if (rules[fieldName]?.max?.value) {
					inputProps.max = rules[fieldName].max.value
				}
			}
			if (type === 'textarea') {
				inputProps.currentText = watch(fieldId) || placeholder || ''
			}
			if (attach_uploader) {
				if (type === 'textarea') {
					inputProps.attachedUploaders = [buildUploader(fieldName + ' uploads')]
				} else if (type === 'multiple-checkbox') {
					inputProps.attachedUploaders = list_options.map(({ option }) =>
						buildUploader(fieldName + ': ' + option + ' uploads')
					)

					inputProps.fieldValue = watch(fieldId)
				}
			}

			inputs[fieldName] = typesToComponents[type](inputProps)
		}
	)

	return (
		<form className="mt-10 flex w-full flex-wrap space-y-8 md:grid md:grid-cols-2 md:space-y-16 md:space-y-reverse">
			<div className="w-11/12 md:w-4/5">{inputs['Your name']}</div>
			<div className="w-11/12 md:w-4/5">{inputs['Your email']}</div>
			<div className="w-11/12 md:w-4/5">{inputs['Company']}</div>
			<div className="w-7/12 md:w-5/12">{inputs['Deadline']}</div>
			<div className="col-span-2 flex w-full justify-between">
				<div className="w-2/5 md:w-4/5">
					{inputs['Is this your first time working with CUUB?']}
				</div>
			</div>

			{watchHowDoYouHear === 'Yes' ? (
				<div className="col-span-2 flex w-full justify-between">
					{inputs["How did you hear about CUUB?*"]}
				</div>
			) : null}

			<div className="col-span-2 w-11/12">
				{inputs['Project']}
			</div>
			<div className="col-span-2 flex w-full justify-between">
				<div className="w-2/5 md:w-4/5">{inputs['Type of services']}</div>
			</div>
			<div className="col-span-2 w-11/12">
				{inputs['Message']}
				<span className="text-xs text-black opacity-40">
					{2000 - (watch(messageFieldId)?.length || 0)} characters of 2000 left.
				</span>
			</div>
			{bottomMessage ? (
				<div className="col-span-2 w-11/12">{bottomMessage}</div>
			) : null}

			<button
				onClick={handleSubmit(submitShortForm)}
				disabled={!watchPersonalAgreement || isUploadInProgress}
				title={isUploadInProgress ? 'Please wait for upload to finish' : ''}
				className="h-16 w-3/5 bg-black font-semibold uppercase tracking-px text-white disabled:opacity-50"
			>
				SEND
			</button>

			<div className="col-span-2 w-11/12">
				<label className="mb-4 flex select-none items-center">
					<input
						className="brief-checkbox focus:outline-none focus:ring-black focus:ring-opacity-0"
						type="checkbox"
						{...register('personal_agreement')}
					/>
					<span className="ml-2">
						I consent to the processing of my personal data*
					</span>
				</label>
				<label className="mb-4 flex select-none items-center">
					<input
						className="brief-checkbox focus:outline-none focus:ring-black focus:ring-opacity-0"
						type="checkbox"
						{...register('special_offers')}
					/>
					<span className="ml-2">
						I want to receive CUUB news and special offers
					</span>
				</label>
			</div>

			{/*<button
				onClick={handleSubmit(onSubmit)}
				disabled={isUploadInProgress}
				title={isUploadInProgress ? 'Please wait for upload to finish' : ''}
				className="uppercase tracking-px font-semibold h-16 w-3/5 disabled:opacity-50"
			>
				Submit short brief
			</button>*/}
		</form>
	)
}

function formatDate(date) {
	let month = (date.getMonth() + 1).toString()
	let day = date.getDate().toString()
	if (month.length === 1) {
		month = '0' + month
	}
	if (day.length === 1) {
		day = '0' + day
	}
	return [date.getFullYear(), month, day].join('-')
}